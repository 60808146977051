import axios from "axios";
import { Buffer } from "buffer";
const DOMAIN = "https://api.kiddiecove.io";
const axiosInstance = axios.create({
  baseURL: `${DOMAIN}/api/`,
  headers: {
    Authorization: `Basic ${Buffer.from("kiddieCove*dev:5426").toString(
      "base64"
    )}`, 
  },
});

export default axiosInstance;
