import React, { useContext } from "react";
import PropTypes from "prop-types";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import logo from "../images/logo_black.svg";
import { withRouter } from "react-router-dom";
import brand from "../api/dummy/brand";
import styles from "./sidebar-jss";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";

import { Menu, MenuItem } from "@material-ui/core";
import { TranslationContext } from "../context";

class SideNavMobile extends React.Component {
  state = { anchorEl: null, openTranslate: false };

  render() {
    const { classes, menuList, closeDrawer, location } = this.props;
    const getLanguage = localStorage.getItem("locale") || "en";

    const handleClick = (event) => {
      this.setState({
        openTranslate: true,
        anchorEl: event.currentTarget,
      });
    };
    const handleClose = (event) => {
      this.setState({ openTranslate: false });
    };
    const handleRequestClose = (lang) => {
      const { changeApplicationLanguage } = this.props;
      changeApplicationLanguage(lang);
      this.setState({ openTranslate: false });
      closeDrawer();
    };

    const getMenus = (menuArray) =>
      menuArray.map((item, index) => (
        <ListItem
          key={index.toString()}
          button
          className={classes.headCapital}
          component={AnchorLink}
          href={item.url}
          onClick={closeDrawer}
        >
          <ListItemText
            classes={{ primary: classes.primary }}
            variant="inset"
            primary={item.name}
          />
        </ListItem>
      ));

    return (
      <div className={classes.drawerInnerMobile}>
        <div className={classes.drawerHeader}>
          <div className={classes.brandBig}>
            <img src={logo} alt={brand.name} />
          </div>
        </div>
        <div
          className={classNames(
            classes.menuContainer,
            classes.landingNav,
            classes.rounded
          )}
        >
          <List className={classes.dense} component="nav">
            <Scrollspy
              items={[
                "feature",
                "showcase",
                "testimonials",
                "tech",
                "pricing",
                "contact",
              ]}
              currentClassName={classes.active}
            >
              {location.pathname === "/" && getMenus(menuList)}

              <ListItem
                key={5}
                button
                className={classes.headCapital}
                style={{ left: "-22px" }}
              >
                <button
                  onClick={handleClick}
                  style={{
                    backgroundColor: "transparent",

                    border: "1px solid whitesmoke",
                    borderWidth: "1px",

                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getLanguage === "en" ? "ENGLISH" : "العربية"}
                  <ArrowDropDown />
                </button>

                <Menu
                  id="simple-menu"
                  anchorEl={this.state.anchorEl}
                  open={this.state.openTranslate}
                  onClick={handleClose}
                >
                  <MenuItem onClick={() => handleRequestClose("en")}>
                    English
                  </MenuItem>
                  <MenuItem onClick={() => handleRequestClose("ar")}>
                    Arabic
                  </MenuItem>
                </Menu>
                <ListItem></ListItem>
              </ListItem>
            </Scrollspy>
          </List>
        </div>
      </div>
    );
  }
}

SideNavMobile.propTypes = {
  classes: PropTypes.object.isRequired,
  menuList: PropTypes.array.isRequired,
  closeDrawer: PropTypes.func.isRequired,
};

const Wrapper = (props) => {
  const { t, i18n, changeApplicationLanguage } = useContext(TranslationContext);
  return (
    <SideNavMobile
      {...props}
      t={t}
      i18n={i18n}
      changeApplicationLanguage={changeApplicationLanguage}
    />
  );
};

export default withRouter(withStyles(styles)(Wrapper));
