import React, { useContext } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";
import { withRouter } from "react-router-dom";

function ParallaxDeco(props) {
  const { classes } = props;
  return (
    <div className={classes.parallaxWrap}>
      <ParallaxProvider>
        <Parallax
          offsetYMax={180}
          offsetYMin={-200}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide3
            )}
          >
            <use xlinkHref="/images/decoration/lineSide3.svg#Line-Side3" />
          </svg>
        </Parallax>
        <Parallax
          offsetYMax={100}
          offsetYMin={-200}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide4
            )}
          >
            <use xlinkHref="/images/decoration/lineSide4.svg#Line-Side4" />
          </svg>
        </Parallax>
      </ParallaxProvider>
    </div>
  );
}

ParallaxDeco.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ParallaxDecoStyled = withStyles(styles)(ParallaxDeco);

class Technology extends React.Component {
  handleNavigate = () => {
    this.props.history.push("/timeline"); 
  };
  render() {
    const { classes, slideMode, t } = this.props;

    return (
      <div className={classes.tech}>
        {!slideMode && <ParallaxDecoStyled />}
        <div className={slideMode ? classes.fullWidth : classes.container}>
          <Title
            title={t("The technologies")}
            desc={t("technologiesDesc")}
            align="center"
            monocolor={slideMode && true}
          />
          <Hidden mdUp>
            <Button
            onClick={this.handleNavigate} 
              variant="contained"
              size="medium"
              color="secondary"
              style={{ marginBottom: "30px" }}
            >
              {t("Request To Implement Technology")}
            </Button>
          </Hidden>
          <Grid container className={classes.root} spacing={3}>
            <Grid item sm={4} xs={12}>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img src={"/images/screen/LGS.svg"} alt="LGS" />
                </figure>
                <Typography variant="h5" className={classes.webpack}>
                  LGS
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img src={"/images/screen/emirates.svg"} alt=" Emirates" />
                </figure>
                <Typography variant="h5" className={classes.webpack}>
                  Emirates
                </Typography>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div className={classes.centerTech}>
                <div
                  className={classNames(
                    classes.wool,
                    slideMode && classes.slideMode
                  )}
                >
                  <figure className={classes.figure}>
                    <img src={"/images/screen/Allied.svg"} alt="Allied" />
                  </figure>
                  <Typography variant="h5" className={classes.webpack}>
                    Allied
                  </Typography>
                </div>
                <Hidden smDown>
                  <Button variant="contained" size="large"  onClick={this.handleNavigate} color="secondary">
                    {t("Request To Implement Technology")}
                  </Button>
                </Hidden>
                <div
                  className={classNames(
                    classes.wool,
                    slideMode && classes.slideMode
                  )}
                >
                  <figure className={classes.figure}>
                    <img
                      src={"/images/screen/becanhouse.svg"}
                      alt="becanhouse"
                    />
                  </figure>
                  <Typography variant="h5" className={classes.webpack}>
                    Beaconhouse
                  </Typography>
                </div>
              </div>
            </Grid>
            <Grid item sm={4} xs={12}>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img src={"/images/screen/lyceum.svg"} alt="lyceum" />
                </figure>
                <Typography variant="h5" className={classes.webpack}>
                  Lyceum
                </Typography>
              </div>
              <div
                className={classNames(
                  classes.wool,
                  slideMode && classes.slideMode
                )}
              >
                <figure className={classes.figure}>
                  <img src={"/images/screen/horizon.svg"} alt="horizon" />
                </figure>
                <Typography variant="h5" className={classes.webpack}>
                  Horizon
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

Technology.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Technology.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);

  return <Technology {...props} t={t} />;
};

export default withRouter(withStyles(styles)(Wrapper));
