import React from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Tooltip,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import sliceText from "../../utils/sliceText";

const useStyles = makeStyles(() => ({
  root: {
    display: "inline-flex",
    gridGap: "10px",
    padding: "8px",
    paddingLeft: "0px",
    borderRadius: "40px",
    paddingRight: "30px",
    alignItems: "center",
  },
  avatar: {
    width: "55px",
    height: "55px",
  },

  time: {
    marginLeft: "1px",
  },

  postTitle: {
    fontSize: "16px",
  },

  postTitleForMobile: {
    fontSize: "12px",
    fontWeight: 400,
  },

  nameComtainer: {
    display: "flex",
    flexDirection: "column",
  },

  nameSubContainer: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
}));

const UserBadge = ({ image = null, name = null, time = null }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <>
      <Box className={classes.root}>
        <Box>
          <Avatar alt={name} src={image} className={classes.avatar}>
            {name && name[0].toUpperCase()}
          </Avatar>
        </Box>
        <Box className={classes.nameComtainer}>
          <Box className={classes.nameSubContainer}>
            <Tooltip title={name} arrow style={{ cursor: "pointer" }} placement="top" >
              <Typography
                className={
                  isMobile ? classes.postTitleForMobile : classes.postTitle
                }
                variant="h6"
              >
                {isMobile ? sliceText(name, 20) : sliceText(name, 30)}
              </Typography>
            </Tooltip>
          </Box>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.time}
          >
            {time}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

UserBadge.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  userType: PropTypes.array.isRequired,
};

export default UserBadge;
