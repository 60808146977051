import React, { Fragment, useContext } from "react";
import Scrollspy from "react-scrollspy";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AppBar from "@material-ui/core/AppBar";
import logo from "../images/logo.svg";
import brand from "../api/dummy/brand";
import SideNavMobile from "./SideNavMobile";
import styles from "./landingStyle-jss";
import { Menu, MenuItem } from "@material-ui/core";
import { TranslationContext } from "../context";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import { ArrowDropUp } from "@material-ui/icons";
import { withRouter } from "react-router-dom";
class Header extends React.Component {
  state = {
    open: false,
    anchorEl: null,
    openTranslate: false,
  };

  toggleDrawerOpen = () => {
    this.setState({ open: true });
  };

  toggleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleClick = (event) => {
    this.setState({
      openTranslate: true,
      anchorEl: event.currentTarget,
      open: true,
    });
  };
  handleClose = () => {
    this.setState({ openTranslate: false, open: false });
  };

  handleRequestClose = (lang) => {
    const { changeApplicationLanguage } = this.props;
    changeApplicationLanguage(lang);
    this.setState({ openTranslate: false, open: false });
  };

  render() {
    const { open } = this.state;
    const { classes, turnDarker, t, i18n, location } = this.props;
    const getLanguage = localStorage.getItem("locale") || "en";
    return (
      <Fragment>
        <Hidden lgUp>
          <SwipeableDrawer
            onClose={this.toggleDrawerClose}
            onOpen={this.toggleDrawerOpen}
            open={open}
            anchor="left"
          >
            <SideNavMobile
              menuList={t("features", { returnObjects: true })}
              closeDrawer={this.toggleDrawerClose}
            />
          </SwipeableDrawer>
        </Hidden>
        <AppBar
          className={classNames(
            classes.header,
            turnDarker && classes.darker,
            classes.gradient
          )}
        >
          <Hidden lgUp>
            <IconButton
              className={classes.menuButton}
              aria-label="Menu"
              onClick={this.toggleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
          </Hidden>

          <div className={classes.container}>
            <div className={classes.spaceContainer}>
              <NavLink
                to="/"
                className={classes.brand}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                <img
                  style={i18n.language === "en" ? {} : { paddingLeft: "20px" }}
                  src={logo}
                  alt={brand.name}
                />
              </NavLink>

              <Hidden mdDown>
                <nav>
                  <Scrollspy
                    items={[
                      "feature",
                      "showcase",
                      "testimonials",
                      "clients",
                      "pricing",
                      "contact",
                    ]}
                    currentClassName="active"
                  >
                    {location.pathname === "/" &&
                      t("features", { returnObjects: true }).map((item) => (
                        <li key={item.id}>
                          <Button component={AnchorLink} href={item.url}>
                            {item.name}
                          </Button>
                        </li>
                      ))}

                    <li style={{ top: "1px" }} key={5}>
                      <div>
                        <button
                          style={{
                            backgroundColor: open ? "#00BFA5" : "transparent",
                            color: "white",
                            border: "1px solid whitesmoke",
                            borderWidth: "1px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          onClick={this.handleClick}
                        >
                          <div>
                            {getLanguage === "en" ? "ENGLISH" : "العربية"}
                          </div>
                          <div>
                            {open ? <ArrowDropUp /> : <ArrowDropDown />}
                          </div>
                        </button>

                        <Menu
                          id="simple-menu"
                          anchorEl={this.state.anchorEl}
                          open={this.state.openTranslate}
                          onClose={() => this.handleClose()}
                          style={{
                            top: "30px",
                          }}
                        >
                          <MenuItem
                            style={
                              i18n.language === "en"
                                ? {
                                    width: "98px",
                                    justifyContent: "center",
                                  }
                                : {
                                    width: "76px",
                                    justifyContent: "center",
                                  }
                            }
                            onClick={() => this.handleRequestClose("en")}
                          >
                            English
                          </MenuItem>
                          <MenuItem
                            style={
                              i18n.language === "en"
                                ? {
                                    width: "98px",
                                    justifyContent: "center",
                                  }
                                : {
                                    width: "76px",
                                    justifyContent: "center",
                                  }
                            }
                            onClick={() => this.handleRequestClose("ar")}
                          >
                            العربية
                          </MenuItem>
                        </Menu>
                      </div>
                    </li>
                  </Scrollspy>
                </nav>
              </Hidden>
            </div>
          </div>
        </AppBar>
      </Fragment>
    );
  }
}

function Wrapper(props) {
  const { t, i18n, changeApplicationLanguage } = useContext(TranslationContext);
  return (
    <Header
      {...props}
      t={t}
      i18n={i18n}
      changeApplicationLanguage={changeApplicationLanguage}
    />
  );
}
export default withRouter(withStyles(styles)(Wrapper));
