import React, { useContext } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";

class Feature extends React.Component {
  render() {
    const { classes, slideMode, t } = this.props;
    return (
      <div
        className={classNames(
          classes.feature,
          slideMode ? classes.mono : classes.color
        )}
      >
        <div className={!slideMode ? classes.container : ""}>
          <Title
            title={t("Main Feature")}
            align="center"
            monocolor={slideMode && true}
          />
          <Grid container className={classes.root} spacing={5}>
            {t("MainFeatures", { returnObjects: true }).map((item) => (
              <Grid key={item.id.toString()} item xs={12} md={4}>
                <Typography component="h4" variant="h6">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <span className={classes.icon}>
                      {
                        // <i className={item.icon} />
                        <img
                          src={item.icon}
                          style={{ marginTop: "5px" }}
                          width={30}
                          heigh={30}
                          alt="feature"
                        />
                      }
                    </span>
                    <span className={classes.featureTitleWrapper}>
                      {" "}
                      {item.title}
                    </span>
                  </div>
                </Typography>
                <Typography className={slideMode ? classes.colorWhite : ""}>
                  {item.desc}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    );
  }
}

Feature.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Feature.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);

  return <Feature t={t} {...props} />;
};

export default withStyles(styles)(Wrapper);
