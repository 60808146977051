import React, { Component, useContext } from "react";
import classNames from "classnames";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";
import "../styles/react-animated-slider.css";
import { Carousel } from "3d-react-carousal";
import Background from "../layout/Background";

function MemoCarousel({ slides }) {
  return (
    <Carousel
      styles={{ zIndex: 1 }}
      slides={slides()}
      autoplay={true}
      interval={7000}
    />
  );
}
class Banner extends Component {
  slides = () => {
    return [
      <img src="/images/kiddie-cove-3.png" alt="3" />,
      <img src="/images/kiddie-cove-2.png" alt="2" />,
      <img src="/images/kiddie-cove-1.png" alt="1" />,
    ];
  };

  render() {
    const { classes, slideMode, t } = this.props;
    return (
      <Background {...this.props}>
        <div className={!slideMode ? classNames(classes.container) : ""}>
          <Typography
            className={classes.titleText}
            component="h1"
            variant="h1"
            gutterBottom
          >
            {t("Kiddiecove")}
          </Typography>
          <Typography component="p" variant="h5" gutterBottom>
            {t("School Management System")}
          </Typography>
          <MemoedCarousel slides={this.slides} />
        </div>
      </Background>
    );
  }
}
const MemoedCarousel = React.memo(MemoCarousel);
Banner.defaultProps = {
  slideMode: false,
};

function Wrapper(props) {
  const { t } = useContext(TranslationContext);
  return <Banner {...props} t={t} />;
}

export default withStyles(styles)(Wrapper);
