import Main from "./Main.js";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import ThemeWrapper from "./ThemeWrapper";
import en from "./Locale/en.json";
import ar from "./Locale/ar.json";
import i18n from "i18next";
import { Suspense } from "react";
import { initReactI18next } from "react-i18next";
import Corporate from "./layout/Corporate";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import TermAndCondition from "./components/TermAndCondition.js";
import Timeline from "./components/Timeline/Index.js";
import ScrollToTop from "./components/ScrollToTop.js";
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    ar: {
      translation: ar,
    },
  },
  lng: "en",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});

function App() {
  return (
    <ThemeWrapper>
      <BrowserRouter>
        <ScrollToTop />
        <Corporate>
          <Switch>
            <Suspense fallback="Loading...">
              <Route path="/timeline" component={Timeline} />
              <Route path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                path="/terms-and-conditions"
                component={TermAndCondition}
              />
              <Route path="/" component={Main} exact />
            </Suspense>
          </Switch>
        </Corporate>
      </BrowserRouter>
    </ThemeWrapper>
  );
}

export default App;
