import { lighten } from "@material-ui/core/styles/colorManipulator";

const styles = (theme) => ({
  skeleton: {
    width: "100% !important",
  },
  skeletonContainer: {
    width: "100% !important",
  },
  trendingList: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: "10px",
    width: "100%",

    "& > *": {
      maxWidth: "100%",
      direction: "ltr",
    },
  },
  descBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(4),
    gap: "10px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
    },
  },
  iconTitle: {
    display: "flex",
    textAlign: "center",
    justifyContent: "center",
    borderRadius: theme.rounded.small,
    border:
      theme.palette.type === "dark"
        ? "none"
        : `1px solid ${lighten(theme.palette.primary.dark, 0.9)}`,
    boxShadow: `0 2px 15px -5px ${theme.palette.primary.main}`,
    background: "rgb(4,186,175)",
    background:
      "linear-gradient(90deg, rgba(4,186,175,1) 0%, rgba(31,154,237,1) 100%)",
    width: 48,
    height: 48,
    alignItems: "center",
    lineHeight: "44px",
    verticalAlign: "middle",

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& svg": {
      width: "60%",
      height: "60%",
      verticalAlign: "middle",
      fill:
        theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
  },
  hashTagCounter: {
    fontSize: "14px",
    wordWrap: "break-word",
    marginTop: "5px",
    direction: theme.direction === "rtl" ? "ltr" : "ltr",
    color:
      theme.palette.type === "dark"
        ? theme.palette.grey[300]
        : theme.palette.grey[500],
  },
  hashtagHeading: {
    textDecoration: "underline",
    fontWeight: "medium",
    // cursor: "pointer",
    direction: "initial",
    wordWrap: "break-word",
  },
  root: theme.mixins.gutters({
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(0),
    boxShadow: theme.shade.light,
    color: theme.palette.text.primary,
    "&$noMargin": {
      margin: 0,
    },
  }),

  title: {
    textAlign: "center",
    position: "relative",
    textTransform: "capitalize",
    fontSize: 24,
    fontWeight: 400,
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    [theme.breakpoints.down("xs")]: {
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
  },
});

export default styles;
