import React, { useContext } from "react";
import Background from "../layout/Background";
import { withStyles } from "@material-ui/core/styles";
import styles from "./landingStyle-jss";
import { Box, Typography, Grid } from "@material-ui/core";
import { TranslationContext } from "../context";

const PrivacyPolicy = ({ classes }) => {
  const { t } = useContext(TranslationContext);

  return (
    <div>
      <section id="banner">
        <Background classes={classes}>
          <Typography component="h2" variant="h2" gutterBottom>
            {t("PrivacyPolicy")}
          </Typography>
          <Box style={{ display: "flex", justifyContent: "center" }} mt={2}>
            <img
              src={"/images/screen/privacyPolicy.svg"}
              alt="Privacy Policy"
              style={{ width: "100%", height: "300px" }}
            />
          </Box>
        </Background>
      </section>
      <section>
        <Box
          className={classes.BoxBackground}
          padding={2}
          minHeight="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography component="h4" variant="h4" gutterBottom>
            {t("PrivacyPolicy")}
          </Typography>

          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="stretch"
          >
            {/* Feature 1: Child Bus Tracking */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                {t("childBusTracking")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("dataCollected")}:</strong> {t("dataCollectedDesc")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>{t("purpose")}:</strong> {t("purposeDesc")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>{t("dataSecurity")}:</strong> {t("dataSecurityDesc")}
              </Typography>
            </Grid>

            {/* Feature 2: Parent-Teacher Communication */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                {t("ParentTeacherCommunication")}{" "}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("dataCollected")}:</strong>{" "}
                {t("teacherCommunicationDescOne")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("purpose")}:</strong>{" "}
                {t("teacherCommunicationDescTwo")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("dataSecurity")}:</strong>{" "}
                {t("teacherCommunicationDescThree")}
              </Typography>
            </Grid>

            {/* Feature 3: Class Activity & Announcements */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                {t("classActivity")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("dataCollected")}:</strong>{" "}
                {t("classActivityDescOne")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("purpose")}:</strong> {t("classActivityDescTwo")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong> {t("dataSecurity")}:</strong>{" "}
                {t("classActivityDescThree")}
              </Typography>
            </Grid>

            {/* Feature 4: Child Leave Application */}
            <Grid item xs={12} sm={6}>
              <Typography variant="h6" gutterBottom>
                {t("childLeaveApplication")}{" "}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>{t("dataCollected")}:</strong>{" "}
                {t("childLeaveApplicationDescOne")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>{t("purpose")}:</strong>{" "}
                {t("childLeaveApplicationDescTwo")}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>{t("dataSecurity")}:</strong>
                {t("childLeaveApplicationDescThree")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  );
};

export default withStyles(styles)(PrivacyPolicy);
