import React, { useContext } from "react";
import Loading from "@material-ui/core/LinearProgress";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/styles";
import { withStyles, MuiThemeProvider } from "@material-ui/core/styles";
import { TranslationContext } from "./context";

const styles = {
  root: {
    width: "100%",
    minHeight: "100%",
    marginTop: 0,
    zIndex: 1,
  },
  loading: {
    zIndex: 10,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    opacity: 1,
    transition: "opacity .5s ease",
  },
  loadingWrap: {
    background: "none",
  },
  bar: {
    background: "rgba(255, 255, 255, 0.7)",
  },
  hide: {
    opacity: 0,
  },
};

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export const AppContext = React.createContext();

class ThemeWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageLoaded: 0,
      palette: undefined,
    };
  }

  componentWillMount = () => {
    this.onProgressShow();
  };

  componentDidMount = () => {
    const { palette } = this.props;
    this.playProgress();
    this.setState({ palette });
  };

  componentWillUnmount() {
    this.onProgressShow();
  }

  onProgressShow = () => {
    this.setState({ pageLoaded: 0 });
  };

  onProgressHide = (val) => {
    this.setState({ pageLoaded: val });
  };

  playProgress = () => {
    let timer = null;
    this.onProgressShow();
    const setCompleted = () => {
      const diff = Math.random() * 40;
      const { pageLoaded } = this.state;
      this.onProgressHide(pageLoaded + diff);
      if (pageLoaded >= 100) {
        clearInterval(timer);
      }
    };
    timer = setInterval(setCompleted, 500);
  };

  render() {
    const { classes, children, theme } = this.props;
    const { pageLoaded } = this.state;
    return (
      <StylesProvider jss={jss}>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Loading
              variant="determinate"
              value={pageLoaded}
              className={pageLoaded >= 100 ? classes.hide : ""}
              classes={{
                root: classes.loading,
                colorPrimary: classes.loadingWrap,
                barColorPrimary: classes.bar,
              }}
            />

            <AppContext.Provider value={this.handleChangeMode}>
              {children}
            </AppContext.Provider>
          </div>
        </MuiThemeProvider>
      </StylesProvider>
    );
  }
}
function Wrapper(props) {
  const { theme } = useContext(TranslationContext);
  return <ThemeWrapper theme={theme} {...props} />;
}
export default withStyles(styles)(Wrapper);
