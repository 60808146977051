import classNames from "classnames";
import React from "react";

export default function Background({
  classes,
  gradient = true,
  slideMode = false,
  children,
}) {
  return (
    <div
      dir="ltr"
      className={classNames(
        classes.banner,
        gradient ? classes.gradient : classes.solid,
        slideMode ? classes.out : classes.fit
      )}
    >
      {children}
    </div>
  );
}
