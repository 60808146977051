import { createMuiTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import applicationTheme from "./theme/applicationTheme";
import getThemeDirectionBasedOnLanguage from "./utils/getThemeDirectionBasedOnLanguage";

const { createContext, useState, useEffect } = require("react");

const TranslationContext = createContext();

const themeColor = "skyBlueTheme";
const themeMode = "light";
const language = localStorage.getItem("locale") || "en";
const initialDirection = getThemeDirectionBasedOnLanguage(language);
const initialTheme = createMuiTheme(
  applicationTheme(themeColor, themeMode, initialDirection)
);

function MyProvider(props) {
  const { t, i18n } = useTranslation();
  const [theme, setTheme] = useState(initialTheme);

  const changeApplicationLanguage = (lang) => {
    const direction = getThemeDirectionBasedOnLanguage(lang);
    localStorage.setItem("locale", lang);
    i18n.changeLanguage(lang);
    document.dir = direction;
    const newTheme = createMuiTheme(
      applicationTheme(themeColor, themeMode, direction)
    );
    setTheme(newTheme);
  };

  useEffect(() => {
    changeApplicationLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TranslationContext.Provider
      value={{ t, i18n, changeApplicationLanguage, theme }}
    >
      {props.children}
    </TranslationContext.Provider>
  );
}

export default MyProvider;
export { TranslationContext };
