import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HeaderLanding from "../components/Header";
import Footer from "../components/Footer";
import styles from "../components/appStyles-jss";

class Corporate extends React.Component {
  state = {
    transform: 0,
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const scroll = window.pageYOffset;
    this.setState({
      transform: scroll,
    });
  };

  render() {
    const { classes, children } = this.props;
    const { transform } = this.state;
    return (
      <div className={classes.appFrameLanding} id="mainContent">
        <HeaderLanding turnDarker={transform > 30} />
        {children}
        <Footer />
      </div>
    );
  }
}

export default withStyles(styles)(Corporate);
