export default function sliceText(text, length) {
  try {
    if (!text) return "";
    if (text.length > length) return text.slice(0, length) + "...";
    return text.slice(0, length);
  } catch (error) {
    console.error("Error occurred while slicing", error);
    return ''
  }
}
