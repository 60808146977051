import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import Slider from "react-animated-slider";
import "../styles/react-animated-slider.css";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";
import PauseIcon from "@material-ui/icons/Pause";

const content = [
  {
    title: "testimonialTitle",
    video: "/videos/testimonial-1.mp4",
    // user: "Luanda Gjokaj",
  },
  {
    title: "testimonialTitle",
    video: "/videos/testimonial-2.mp4",
    // user: "Erich Behrens",
  },
];

function ParallaxDeco(props) {
  const { classes } = props;

  return (
    <div className={classes.parallaxWrap}>
      <ParallaxProvider>
        <Parallax
          offsetYMax={180}
          offsetYMin={-200}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide1
            )}
          >
            <use xlinkHref="/images/decoration/lineSide1.svg#Line-Side1" />
          </svg>
        </Parallax>
        <Parallax
          offsetYMax={100}
          offsetYMin={-350}
          slowerScrollRate
          tag="figure"
        >
          <svg
            fill="#fff"
            className={classNames(
              classes.parallaxVertical,
              classes.parallaxLineSide2
            )}
          >
            <use xlinkHref="/images/decoration/lineSide2.svg#Line-Side2" />
          </svg>
        </Parallax>
      </ParallaxProvider>
    </div>
  );
}

ParallaxDeco.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ParallaxDecoStyled = withStyles(styles)(ParallaxDeco);

class Testimonials extends React.Component {
  render() {
    const {
      classes,
      slideMode,
      t,
      playingIndex,
      handlePlayPauseClick,
      videoRefs,
      setPlayingIndex,
      isPlaying,
      setIsPlaying,
    } = this.props;
    return (
      <div className={classes.testimonials}>
        {!slideMode && <ParallaxDecoStyled />}
        <div
          className={!slideMode ? classes.container : classes.fullSliderContent}
        >
          <Title
            title={t("What people said")}
            align="center"
            monocolor={slideMode && true}
          />
          <div className={classes.sliderWrap}>
            <Slider className="slider-wrapper">
              {content.map((item, index) => (
                <div
                  key={index.toString()}
                  className="slider-content"
                  style={{
                    background: `url('${item.image}') no-repeat center center`,
                  }}
                >
                  {item.video && (
                    <video
                      ref={(el) => (videoRefs.current[index] = el)}
                      src={item.video}
                      style={{ width: "100%", height: "auto" }}
                      controls={false}
                      onEnded={() => {
                        setIsPlaying(false);
                        setPlayingIndex(null);
                      }}
                    />
                  )}
                  <IconButton
                    aria-label={
                      isPlaying && playingIndex === index ? "Pause" : "Play"
                    }
                    className={classes.playIcon}
                    onClick={() => handlePlayPauseClick(index)}
                  >
                    {isPlaying && playingIndex === index ? (
                      <PauseIcon />
                    ) : (
                      <PlayArrowIcon />
                    )}
                  </IconButton>
                  <p
                    className={classNames(
                      classes.videoCaption,
                      slideMode ? classes.mono : classes.color
                    )}
                  >
                    <Typography
                      variant="h6"
                      component="span"
                      gutterBottom
                      className={classes.testimonialTitle}
                    >
                      {t(item.title)}
                    </Typography>
                    <Typography component="span">{item.user}</Typography>
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

Testimonials.propTypes = {
  classes: PropTypes.object.isRequired,
  slideMode: PropTypes.bool,
};

Testimonials.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);
  const [playingIndex, setPlayingIndex] = useState(null);
  const videoRefs = useRef([]);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPauseClick = (index) => {
    if (
      playingIndex !== null &&
      playingIndex !== index &&
      videoRefs.current[playingIndex]
    ) {
      videoRefs.current[playingIndex].pause();
    }

    if (playingIndex === index && isPlaying) {
      videoRefs.current[index].pause();
      setIsPlaying(false);
    } else {
      setPlayingIndex(index);
      videoRefs.current[index].play();
      setIsPlaying(true);
    }
  };

  return (
    <Testimonials
      {...props}
      t={t}
      playingIndex={playingIndex}
      videoRefs={videoRefs}
      handlePlayPauseClick={handlePlayPauseClick}
      setPlayingIndex={setPlayingIndex}
      isPlaying={isPlaying}
      setIsPlaying={setIsPlaying}
    />
  );
};

export default withStyles(styles)(Wrapper);
