import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import classNames from "classnames";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import styles from "./cardStyle-jss";
import { TranslationContext } from "../context";

class PricingCard extends React.Component {
  render() {
    const { classes, title, price, feature, tier, t, i18n } = this.props;

    const getTier = (lv) => {
      switch (lv) {
        case "cheap":
          return classes.cheap;
        case "expensive":
          return classes.expensive;
        case "more-expensive":
          return classes.moreExpensive;
        default:
          return classes.FREE;
      }
    };

    return (
      <Card className={classNames(classes.priceCard, getTier(tier))}>
        <div className={classes.priceHead}>
          <Typography variant="h5">{title}</Typography>
          <Typography component="h4" variant="h2">
            {price}
          </Typography>
        </div>
        <CardContent className={classes.featureList}>
          <ul>
            {feature.map((item, index) => (
              <li
                key={index.toString()}
                style={
                  i18n.language === "en"
                    ? { listStyleType: "none", marginLeft: "-40px" }
                    : { listStyleType: "none", marginLeft: "40px" }
                }
              >
                {item}
              </li>
            ))}
          </ul>
        </CardContent>
        <CardActions className={classes.btnArea}>
          <Button
            variant="outlined"
            size="large"
            className={classes.lightButton}
          >
            {t("Get in now")}
          </Button>
        </CardActions>
      </Card>
    );
  }
}

PricingCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  tier: PropTypes.string.isRequired,
  feature: PropTypes.array.isRequired,
};

const Wrapper = (props) => {
  const { t, i18n } = useContext(TranslationContext);

  return <PricingCard {...props} t={t} i18n={i18n} />;
};

export default withStyles(styles)(Wrapper);
