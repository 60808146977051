import React from "react";
import { Card, CardHeader, CardContent, CardMedia } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  cardSocmed: {
    maxWidth: "100%",
    margin: "1rem auto",
    marginTop: "0px",
  },
  media: {
    height: 250,
  },
  descriptions: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: 40,
    height: 40,
  },
  content: {
    marginBottom: theme.spacing(2),
  },
}));

const PostSkeleton = () => {
  const classes = useStyles();

  return (
    <Card className={classes.cardSocmed}>
      <CardHeader
        avatar={
          <Skeleton animation="wave" variant="circle" width={40} height={40} />
        }
        title={<Skeleton animation="wave" width="40%" />}
        subheader={<Skeleton animation="wave" width="20%" />}
      />

      <Skeleton animation="wave" variant="rect" className={classes.media} />

      <CardContent>
        <div className={classes.descriptions}>
          <Skeleton animation="wave" width="90%" />
          <Skeleton animation="wave" width="80%" />
          <Skeleton animation="wave" width="60%" />
        </div>
      </CardContent>
    </Card>
  );
};

export default PostSkeleton;
