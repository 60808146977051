import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState } from "react";
import styles from "./timeline-jss";
import ImageModal from "./ImageModal";
import UserBadge from "./UserBadge";
import parse from "html-react-parser";

const PostCard = ({ data, classes }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (image) => {
    setModalImage(image);
    setModalOpen(true);
  };
  return (
    <>
      <li key={data._id}>
        <Card className={classes.cardSocmed}>
          <CardHeader
            title={
              <UserBadge
                image={data.school_id ? data.school_id?.avatar: data.tenant_id.avatar}
                name={data.school_id ? data.school_id?.title: data.tenant_id.org_name}
                time={new Date(data.created_at).toDateString()}
              />
            }
          />
          {Boolean(data.image) && (
            <CardActionArea onClick={() => handleImageClick(data.image)}>
              <CardMedia
                className={classes.media}
                image={data.image}
                title={data.name}
              />
            </CardActionArea>
          )}
          <CardContent className={classes.postDescriptionRoot}>
            {data.content && (
              <Typography className={classes.descriptions}>
                {data.content && parse(data.content)}
              </Typography>
            )}
          </CardContent>
        </Card>
      </li>
      <ImageModal open={modalOpen} setOpen={setModalOpen} image={modalImage} />
    </>
  );
};

export default withStyles(styles)(PostCard);
