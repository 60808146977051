import React, { useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./landingStyle-jss";
import Background from "../layout/Background";
import { Box, Typography, Grid } from "@material-ui/core";
import { TranslationContext } from "../context";

const TermAndCondition = ({ classes }) => {
  const { t } = useContext(TranslationContext);

  return (
    <div>
      <section id="banner">
        <Background classes={classes}>
          <Typography component="h2" variant="h2" gutterBottom>
            {t("TermsAndCondition")}
          </Typography>
          <Box style={{ display: "flex", justifyContent: "center" }} mt={2}>
            <img
              src={"/images/screen/termsConditions.svg"}
              alt="Terms and Conditions"
              style={{ width: "100%", height: "300px" }}
            />
          </Box>
        </Background>
      </section>

      <section>
        <Box className={classes.BoxBackground} p={3}>
          <Typography component="h4" variant="h4" gutterBottom>
            {t("TermsAndCondition")}
          </Typography>
          <Typography component="p" variant="body1" gutterBottom>
            {t("TermsAndConditionDesc")}
          </Typography>

          {/* Child Bus Tracking Section */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography component="h5" variant="h5">
                {t("childBusTracking")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childBusTrackingFeature")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childBusTrackingFeatureDescOne")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childBusTrackingFeatureDescTwo")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childBusTrackingFeatureDescThree")}
              </Typography>
            </Grid>

            {/* Parent-Teacher Communication Section */}
            <Grid item xs={12} md={6}>
              <Typography component="h5" variant="h5">
                {t("parentTeacherCommunication")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("parentTeacherCommunicationFeature")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("parentTeacherCommunicationFeatureDescOne")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("parentTeacherCommunicationFeatureDescTwo")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("parentTeacherCommunicationFeatureDescThree")}
              </Typography>
            </Grid>
          </Grid>

          {/* Class Activity & Announcements Section */}
          <Grid container spacing={3} mt={2}>
            <Grid item xs={12} md={6}>
              <Typography component="h5" variant="h5">
                {t("classActivityAnnouncements")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("classActivityAnnouncementsFeature")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("classActivityAnnouncementsFeatureDescOne")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("classActivityAnnouncementsFeatureDescTwo")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("classActivityAnnouncementsFeatureDescThree")}
              </Typography>
            </Grid>

            {/* Child Leave Application Submission Section */}
            <Grid item xs={12} md={6}>
              <Typography component="h5" variant="h5">
                {t("childLeaveApplicationSubmission")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childLeaveApplicationSubmissionFeature")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childLeaveApplicationSubmissionFeatureDescOne")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childLeaveApplicationSubmissionFeatureDescTwo")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childLeaveApplicationSubmissionFeatureDescThree")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("childLeaveApplicationSubmissionFeatureDescFour")}
              </Typography>
            </Grid>
          </Grid>

          {/* School Management System Section */}
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                {t("schoolManagementSystem")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("schoolManagementSystemFeature")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("schoolManagementSystemDescOne")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("schoolManagementSystemDescTwo")}
              </Typography>
              <Typography component="p" variant="body2">
                {t("schoolManagementSystemDescThree")}
              </Typography>
            </Grid>
          </Grid>

          {/* General Conditions Section */}
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12}>
              <Typography component="h4" variant="h4" gutterBottom>
                {t("generalConditions")}
              </Typography>
              <Typography component="p" variant="body2" gutterBottom>
                - {t("dataPrivacy")}: {t("generalConditionsDescOne")}
              </Typography>
              <Typography component="p" variant="body2" gutterBottom>
                - {t("ChangesTerms")}: {t("generalConditionsDescTwo")}
              </Typography>
              <Typography component="p" variant="body2" gutterBottom>
                - {t("liability")}: {t("generalConditionsDescThree")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </section>
    </div>
  );
};

export default withStyles(styles)(TermAndCondition);
