const languages = [
  {
    id: "en",
    displayName: "English",
    direction: "ltr",
  },
  {
    id: "ar",
    displayName: "Arabic",
    direction: "rtl",
  },
];

export default languages;
