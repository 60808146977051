import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import withWidth from "@material-ui/core/withWidth";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import Grid from "@material-ui/core/Grid";
import ShowcaseCard from "../Cards/ShowcaseCard";
import Title from "./Title";
import styles from "./landingStyle-jss";
import { TranslationContext } from "../context";

function ParallaxDeco(props) {
  const { classes } = props;
  return (
    <div className={classes.parallaxWrap}>
      <ParallaxProvider>
        <div className={classes.bannerParallaxWrap}>
          <Parallax
            offsetYMax={70}
            offsetYMin={-200}
            slowerScrollRate
            tag="figure"
          >
            <svg
              fill="#fff"
              className={classNames(
                classes.parallaxVertical,
                classes.parallaxPetal1
              )}
            >
              <use xlinkHref="/images/decoration/petal3.svg#Petal-3" />
            </svg>
          </Parallax>
          <Parallax
            offsetYMax={100}
            offsetYMin={-200}
            slowerScrollRate
            tag="figure"
          >
            <svg
              fill="#fff"
              className={classNames(
                classes.parallaxVertical,
                classes.parallaxPetal2
              )}
            >
              <use xlinkHref="/images/decoration/petal4.svg#Petal-4" />
            </svg>
          </Parallax>
        </div>
      </ParallaxProvider>
    </div>
  );
}

ParallaxDeco.propTypes = {
  classes: PropTypes.object.isRequired,
};

const ParallaxDecoStyled = withStyles(styles)(ParallaxDeco);

class Showcase extends React.Component {
  render() {
    const { classes, slideMode, width, t } = this.props;
    return (
      <section className={classes.showcase}>
        {!slideMode && <ParallaxDecoStyled />}
        <div className={classes.container}>
          <Grid container className={classes.root} spacing={5}>
            <Grid item sm={6} md={4} xs={12}>
              <Title
                title={t("Showcase")}
                align={width === "lg" ? "left" : "center"}
                monocolor={slideMode && true}
              />
              <ShowcaseCard
                title={t("busTracking")}
                desc={t("busTrackingDesc")}
                action={t("Try it")}
                image="/images/screen/busTracking.svg"
              />
              <ShowcaseCard
                title={t("parentTeacherCommunication")}
                desc={t("parentTeacherCommunicationDesc")}
                action={t("See Demo")}
                image="/images/screen/parentTeacherChat.svg"
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <ShowcaseCard
                title={t("classActivityAnnouncements")}
                desc={t("classActivityAnnouncementsDesc")}
                action={t("See Demo")}
                image="/images/screen/leaveApplicatio.svg"
              />
              <ShowcaseCard
                title={t("feeManagement")}
                desc={t("submitChildApplicationsDesc")}
                action={t("Try it")}
                image="/images/screen/classUpdateAnnouncements.svg"
              />
            </Grid>
            <Grid item sm={6} md={4} xs={12}>
              <div className={classes.lastShowcase}>
                <ShowcaseCard
                  title={t("comprehensiveSchoolManagement")}
                  desc={t("comprehensiveSchoolManagementDesc")}
                  action={t("See Demo")}
                  image="/images/screen/feeManagement.svg"
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </section>
    );
  }
}

Showcase.propTypes = {
  classes: PropTypes.object.isRequired,
  width: PropTypes.string.isRequired,
  slideMode: PropTypes.bool,
};

Showcase.defaultProps = {
  slideMode: false,
};

const Wrapper = (props) => {
  const { t } = useContext(TranslationContext);
  return <Showcase {...props} t={t} />;
};

export default withWidth()(withStyles(styles)(Wrapper));
